<div class="wrapper">
  @if (config()) {
    <router-outlet></router-outlet>
  } @else {
    <div class="spinner">
      <div class="spinner-wrapper">
        <app-spinner />
      </div>
      <p>Loading resources</p>
    </div>
  }
</div>
<div class="toast">
  <app-toast />
</div>
<div class="modal">
  <app-modal />
</div>
