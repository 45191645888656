import { Component, HostListener, OnInit, signal } from '@angular/core';
import { ConfigOfficeService } from '@libs/app.services/office/config-office.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ModalComponent } from '@libs/stand-alone-components/commons/modal/modal.component';
import { Router, RouterModule } from '@angular/router';
import { SpinnerComponent } from '@libs/stand-alone-components/commons/spinner/spinner';
import { ToastComponent } from '@libs/stand-alone-components/commons/toast/toast.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['app.component.scss'],

  imports: [CommonModule, TranslateModule, ToastComponent, RouterModule, ModalComponent, SpinnerComponent],
})
export class AppComponent implements OnInit {
  path = signal<string>(window.location.pathname);
  config = toSignal(
    this.configOfficeService.init().pipe(tap(() => setTimeout(() => this.router.navigateByUrl(this.path()), 100))),
  );
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    const innerWidth = window.innerWidth;
    document.documentElement.style.setProperty('--display-w', `${innerWidth}px`);
  }
  constructor(
    private configOfficeService: ConfigOfficeService,
    private router: Router,
  ) {}
  ngOnInit(): void {
    setInterval(() => this.onResize(), 1000);
  }
}
