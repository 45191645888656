import { Route } from '@angular/router';
import { AuthOfficeService } from '@libs/app.services/office/auth.office.service';
import { EmployeeRoleService } from '@libs/app.services/office/route.canactivate.service';
import { HomeComponent } from './components/layout/home/home.component';
import { GamesResolver } from './resolvers/games.resolver';

export const appRoutes: Route[] = [
  {
    path: 'auth',
    loadComponent: () => import('./components/sign/auth').then(m => m.AuthComponent),
    children: [
      {
        path: 'sign',
        loadComponent: () => import('./components/sign/signin/signin.component').then(m => m.SigninComponent),
      },
      {
        path: 'code/:email',
        loadComponent: () => import('./components/sign/code/code.component').then(m => m.AuthCodeComponent),
      },
    ],
  },
  {
    path: '',
    loadComponent: () => import('./components/layout/main.component').then(m => m.MainComponent),
    canActivate: [AuthOfficeService],
    children: [
      { path: '', component: HomeComponent },
      {
        path: 'telegram',
        loadComponent: () =>
          import('./components/features/telegram/telegram-chat.component').then(m => m.TelegramChatComponent),
      },
      {
        path: 'user',
        loadComponent: () => import('./components/features/user/user.component').then(m => m.UserComponent),
        children: [
          {
            path: 'global',
            loadComponent: () =>
              import('./components/features/user/user-global/user-global.component').then(c => c.UserGlobalComponent),
          },
          {
            path: 'data',
            loadComponent: () =>
              import('./components/features/user/user-data/user-data.component').then(c => c.UserDataComponent),
          },
          {
            path: 'stats',
            loadComponent: () =>
              import('./components/features/user/user-stats/user-stats.component').then(c => c.UserStatsComponent),
          },
          {
            path: 'docs',
            loadComponent: () =>
              import('./components/features/user/user-docs/user-docs.component').then(c => c.UserDocsComponent),
          },
          {
            path: 'pending-bets',
            loadComponent: () =>
              import('./components/features/user/user-bets/user-bets.component').then(c => c.UserBetsComponent),
          },
          {
            path: 'historical-draws',
            loadComponent: () =>
              import('./components/features/user/user-historical-draws/user-historical-draws.component').then(
                c => c.UserHistoricalComponent,
              ),
          },
          {
            path: 'historical-games',
            loadComponent: () =>
              import('./components/features/user/user-historical-games/user-historical-games.component').then(
                c => c.UserHistoricalGamesComponent,
              ),
          },
          {
            path: 'historical-sporting-bets',
            loadComponent: () =>
              import('./components/features/user/user-historical-sports/user-historical-sports.component').then(
                c => c.UserHistoricalSportsComponent,
              ),
          },
          {
            path: 'transactions',
            loadComponent: () =>
              import('./components/features/user/user-transactions/wallet-transactions.component').then(
                c => c.WalletTransactionsComponent,
              ),
          },
          {
            path: 'deposits',
            loadComponent: () =>
              import('./components/features/user/user-deposits/user-deposits.component').then(
                c => c.UserDepositsComponent,
              ),
          },
          {
            path: 'withdrawals',
            loadComponent: () =>
              import('./components/features/user/user-withdrawals/user-withdrawals.component').then(
                c => c.UserWithdrawalsComponent,
              ),
          },
          {
            path: 'promo',
            loadComponent: () =>
              import('./components/features/user/user-promo/user-promo.component').then(c => c.UserPromoComponent),
          },
          {
            path: 'alert',
            loadComponent: () =>
              import('./components/features/user/user-alerts/user-alerts.component').then(c => c.UserAlertsComponent),
          },
          {
            path: 'notes',
            loadComponent: () =>
              import('./components/features/user/user-notes/user-notes.component').then(c => c.UserNotesComponent),
          },
          {
            path: 'referals',
            loadComponent: () =>
              import('./components/features/user/user-rewards/user-rewards.component').then(
                c => c.UserRewardsComponent,
              ),
          },
        ],
        canActivate: [EmployeeRoleService],
      },
      {
        path: 'stats',
        loadComponent: () => import('./components/features/stats/stats.component').then(m => m.StatsComponent),
        children: [
          {
            path: 'users',
            loadComponent: () =>
              import('./components/features/stats/users-stats/users-stats.component').then(c => c.UsersStatsComponent),
          },
          {
            path: 'telegram-connected',
            loadComponent: () =>
              import('./components/features/stats/telegram-connected/telegram-connected.component').then(
                c => c.TelegramConnectedComponent,
              ),
          },
          {
            path: 'months',
            loadComponent: () =>
              import('./components/features/stats/stats-monthly/stats-monthly.component').then(
                c => c.StatsMonthlyComponent,
              ),
          },
          {
            path: 'games',

            loadComponent: () =>
              import('./components/features/stats/games/games.component').then(c => c.GamesComponent),
          },
          {
            path: 'sports',
            loadComponent: () =>
              import('./components/features/stats/sports/sports.component').then(c => c.SportsStatsComponent),
          },
          {
            path: 'users-daily-activity',
            loadComponent: () =>
              import('./components/features/stats/usersDailyActivity/users-daily-activity.component').then(
                c => c.UsersDailyActivityComponent,
              ),
          },
        ],
        canActivate: [EmployeeRoleService],
      },
      {
        path: 'docs',
        loadComponent: () =>
          import('./components/features/docs/pending-users-docs.component').then(m => m.PendingUsersDocsComponent),
        canActivate: [EmployeeRoleService],
      },
      {
        path: 'deposits',
        loadComponent: () => import('./components/features/deposits/deposits.component').then(m => m.DepositsComponent),
        canActivate: [EmployeeRoleService],
      },
      {
        path: 'withdraws',
        loadComponent: () =>
          import('./components/features/withdraws/withdraws.component').then(m => m.WithdrawsComponent),
        canActivate: [EmployeeRoleService],
      },
      {
        path: 'draws',
        loadComponent: () => import('./components/features/draws/draws.component').then(m => m.DrawsComponent),
        canActivate: [EmployeeRoleService],
        children: [
          {
            path: 'locator',
            loadComponent: () =>
              import('@libs/stand-alone-components/bicho/commons/draws/draws-locator/draws-locator').then(
                m => m.DrawsLocatorComponent,
              ),
          },
          // {
          //   path: 'draw-finished',
          //   loadComponent: () =>
          //     import('../features/draws/draw-finished/draw-finished.component').then(m => m.DrawFinishedComponent),
          // },
          // {
          //   path: 'draw-pending',
          //   loadComponent: () =>
          //     import('../features/draws/draw-pending/draw-pending.component').then(m => m.DrawPendingComponent),
          // },
        ],
      },
      // {
      //   path: 'sports',
      //   loadChildren: () => import('../features/sports/app.module').then(m => m.AppModule),
      //   canActivate: [EmployeeRoleService],
      // },
      {
        path: 'games',
        resolve: {
          games: GamesResolver,
        },
        loadComponent: () => import('./components/features/games/games-management').then(m => m.GamesComponent),
        children: [
          {
            path: 'categories',
            loadComponent: () =>
              import('./components/features/games/categoryGames/category-games.component').then(
                c => c.CategoryGamesComponent,
              ),
          },
          {
            path: 'providers',
            loadComponent: () =>
              import('./components/features/games/providers-management/providers-management.component').then(
                c => c.ProvidersManagementComponent,
              ),
          },
        ],
        canActivate: [EmployeeRoleService],
      },
      // {
      //   path: 'gallery',
      //   loadChildren: () => import('../features/gallery/app.module').then(m => m.AppModule),
      //   canActivate: [EmployeeRoleService],
      // },
      // {
      //   path: 'affiliates',
      //   loadChildren: () => import('../features/affiliates/app.module').then(m => m.AppModule),
      //   canActivate: [EmployeeRoleService],
      // },
    ],
  },
  // { path: '**', redirectTo: '' },
];
