import { TAB_SECTION, ITabSection } from '@libs/interfaces';

export const LOBBY_TABS: ITabSection[] = [
  {
    tab: TAB_SECTION.LOBBY,
    label: 'All',
    icon: 'all',
    sections: [TAB_SECTION.NEW, TAB_SECTION.HOT, TAB_SECTION.CRASH, TAB_SECTION.TABLE, TAB_SECTION.ROULETTE],
    background: 'assets/images/representacion-3d-juegos-azar-linea.jpg',
  },
  {
    tab: TAB_SECTION.FAVOURITES,
    label: 'Favourites',
    icon: 'star',
    sections: [TAB_SECTION.FAVOURITES],
  },
  {
    tab: TAB_SECTION.SLOTS,
    label: 'Slots',
    icon: 'slots',
    sections: [TAB_SECTION.SLOTS],
  },
  {
    tab: TAB_SECTION.TABLE,
    label: 'TGG',
    icon: 'table',
    sections: [TAB_SECTION.TABLE],
  },
  {
    tab: TAB_SECTION.CRASH,
    label: 'crash',
    icon: 'crash',
    sections: [TAB_SECTION.CRASH],
  },
  {
    tab: TAB_SECTION.ROULETTE,
    label: 'Roulette',
    icon: 'roulette',
    sections: [TAB_SECTION.ROULETTE],
  },
];
export const LOBBY_TABS_NEW = [
  {
    tab: TAB_SECTION.LOBBY,
    label: 'All',
    icon: 'all',
    sections: [TAB_SECTION.NEW, TAB_SECTION.HOT, TAB_SECTION.CRASH, TAB_SECTION.TABLE, TAB_SECTION.ROULETTE],
    number: 0,
  },
  {
    tab: TAB_SECTION.FAVOURITES,
    label: 'Favourites',
    icon: 'star',
    sections: [TAB_SECTION.FAVOURITES],
    number: 0,
  },
  {
    tab: TAB_SECTION.SLOTS,
    label: 'Slots',
    icon: 'slots',
    sections: [TAB_SECTION.SLOTS],
    number: 0,
  },
  {
    tab: TAB_SECTION.TABLE,
    label: 'TGG',
    icon: 'table',
    sections: [TAB_SECTION.TABLE],
    number: 0,
  },
  {
    tab: TAB_SECTION.CRASH,
    label: 'crash',
    icon: 'crash',
    sections: [TAB_SECTION.CRASH],
    number: 0,
  },

  {
    tab: TAB_SECTION.ROULETTE,
    label: 'Roulette',
    icon: 'roulette',
    sections: [TAB_SECTION.ROULETTE],
    number: 0,
  },
  {
    tab: TAB_SECTION.POKER,
    label: 'Poker',
    icon: 'poker',
    sections: [TAB_SECTION.POKER],
    number: 0,
  },
  {
    tab: TAB_SECTION.BLACKJACK,
    label: 'Blackjack',
    icon: 'blackjack',
    sections: [TAB_SECTION.BLACKJACK],
    number: 0,
  },
  {
    tab: TAB_SECTION.BACCARAT,
    label: 'Baccarat',
    icon: 'baccarat',
    sections: [TAB_SECTION.BACCARAT],
    number: 0,
  },

  {
    tab: TAB_SECTION.BINGO,
    label: 'Bingo',
    icon: 'bingo',
    sections: [TAB_SECTION.BINGO],
    number: 0,
  },
  {
    tab: TAB_SECTION.LOTTERY,
    label: 'Lottery',
    icon: 'lottery',
    sections: [TAB_SECTION.LOTTERY],
    number: 0,
  },
];
